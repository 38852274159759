import React, { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext({
    theme: "light",
    toggleTheme: () => { },
});

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState("dark"); // Default to dark mode

    useEffect(() => {
        const root = window.document.documentElement;

        // Check for saved user preference
        const savedTheme = localStorage.getItem("theme");

        // Use the saved theme if it exists, otherwise default to dark
        const initialTheme = savedTheme || "dark";

        // Apply the initial theme
        root.classList.add(initialTheme);
        setTheme(initialTheme);
    }, []);

    const toggleTheme = () => {
        const root = window.document.documentElement;
        const newTheme = theme === "light" ? "dark" : "light";

        root.classList.remove(theme);
        root.classList.add(newTheme);

        // Save the new theme preference
        localStorage.setItem("theme", newTheme);

        setTheme(newTheme);
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);